// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot
import './styles/main.scss';
import App from './App';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Tworzenie root

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
