import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import '../styles/Contact.scss'; // Import nowego pliku stylów

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === 'message') {
      setCharCount(value.length);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);
    setHasError(false);

    emailjs
      .send(
        'service_hdmsdz8',  // Service ID
        'template_z471j3h', // Template ID
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
        },
        'Ts1cgB0U3ZocCoynR'  // User ID
      )
      .then((response) => {
        setIsSending(false);
        setIsSent(true);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setIsSending(false);
        setHasError(true);
      });
  };

  if (isSent) {
    return (
      <div className="thank-you-message">
        <h2>Dziękuję za wiadomość. Skontaktuję się w najbliższym czasie.</h2>
      </div>
    );
  }

  if (hasError) {
    return (
      <div className="error-message">
        <h2>Przepraszamy, wystąpił błąd podczas wysyłania wiadomości. Proszę o kontakt telefoniczny.</h2>
      </div>
    );
  }

  return (
    <div className="contact-container">
      <section className="contact-header">
        <h1>Kontakt</h1>
      </section>

      <section className="contact-form-section">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-left">
            <div className="form-group">
              <label htmlFor="name">Imię i nazwisko</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                title="Proszę wpisać swoje imię i nazwisko"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                title="Proszę wpisać swój adres e-mail"
              />
            </div>
          </div>
          <div className="form-right">
            <div className="form-group">
              <label htmlFor="message">Wiadomość</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                maxLength="500"
                required
                title="Proszę wpisać wiadomość (max 500 znaków)"
              />
              <p className="char-counter">{charCount}/500 znaków</p>
            </div>
            <button type="submit" className="send-button" disabled={isSending}>
              {isSending ? 'Wysyłanie...' : 'Wyślij'}
            </button>
          </div>
        </form>
      </section>

      <section className="contact-info-section">
        {/* <div className="contact-item">
          <FaPhoneAlt className="contact-icon" />
          <span>+48 123 456 789</span>
        </div> */}
        <div className="contact-item">
          <FaEnvelope className="contact-icon" />
          <span>wiannekstudio@gmail.com</span>
        </div>
      </section>
    </div>
  );
}

export default Contact;
