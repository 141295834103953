import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import '../styles/main.scss'; // Importowanie SCSS

function Footer() {
  return (
    <footer className="footer">
      <div className="social-media">
        <a href="https://instagram.com/wiannek_studio" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="icon" />
        </a>
        <a href="https://tiktok.com/@wiannek_studio" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTiktok} className="icon" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
