import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'; // Import Header
import Footer from './components/Footer'; // Import Footer
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Offer from './pages/Offer';
import ReadyProducts from './pages/ReadyProducts';
import AddProduct from './pages/AddProduct';
import Product from './pages/Product';
import Login from './pages/Login';
import Register from './pages/Register';
import { CartProvider } from './context/CartContext'; // Import CartProvider

import './styles/main.scss'; // Importowanie SCSS

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };
  
  return (
    <CartProvider>
      <Router>
        <div className="app">
          <Header isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/offer" element={<Offer />} />
            <Route path="/ReadyProducts" element={<ReadyProducts />} />
            <Route path="/AddProduct" element={<AddProduct />} />
            <Route path="/produkt/:slug" element={<Product />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login setAuth={setIsAuthenticated} />} />
            {/* <Route path="/cart" element={<CartPage />} /> */}
            {/* Dodaj inne trasy, jeśli potrzeba */}
          </Routes>
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
