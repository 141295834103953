// src/pages/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../styles/Login.scss';

function Login({ setAuth }) {
  const [inputs, setInputs] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const handleChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.post('http://localhost:3001/api/login', inputs);
      localStorage.setItem('token', res.data.token);
      setUsername(res.data.user.username);
      setLoggedIn(true);
      setAuth(true);
    } catch (err) {
      console.error(err);
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : 'Logowanie nie powiodło się. Spróbuj ponownie.';
      setErrorMessage(message);

      // Czyszczenie pola hasła
      setInputs(prevInputs => ({ ...prevInputs, password: '' }));
    }
  };

  return (
    <div className="login-container">
      <h1>Zaloguj się</h1>
      {loggedIn ? (
        <p className="success-message">Logowanie poprawne, witaj {username}</p>
      ) : (
        <>
          <form onSubmit={handleSubmit} className="login-form">
            <input
              type="email"
              name="email"
              placeholder="Adres email"
              value={inputs.email}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Hasło"
              value={inputs.password}
              onChange={handleChange}
              required
            />
            <button type="submit">Zaloguj</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
          <p>
            Nie masz konta?{' '}
            <Link to="/register" className="register-link">
              Zarejestruj się
            </Link>
          </p>
        </>
      )}
    </div>
  );
}

export default Login;
