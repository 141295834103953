// // src/components/Header.js
// import React, { useState } from 'react';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { FaShoppingCart, FaUser, FaBars } from 'react-icons/fa';
// import '../styles/Header.scss';
// import logo from '../assets/images/logo2.png';

// function Header({ cartItemCount = 0, isAuthenticated = false, handleLogout }) {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [menuOpen, setMenuOpen] = useState(false);

//   const handleScroll = (id) => {
//     const element = document.getElementById(id);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth' });
//       setMenuOpen(false);
//     }
//   };

//   const handleNavigation = (id) => {
//     if (location.pathname === '/offer') {
//       handleScroll(id);
//     } else {
//       navigate('/offer');
//       setTimeout(() => handleScroll(id), 500);
//     }
//   };

//   const handleRefresh = (path) => {
//     if (location.pathname === path) {
//       window.location.reload();
//     } else {
//       navigate(path);
//     }
//     setMenuOpen(false);
//   };

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   return (
//     <header className="header">
//       <div className="logo">
//         <Link to="/" onClick={() => handleRefresh('/')}>
//           <img src={logo} alt="Logo" />
//         </Link>
//       </div>

//       {/* Ikona menu mobilnego */}
//       {/* <div className="mobile-menu-icon" onClick={toggleMenu}>
//         <FaBars />
//       </div> */}

//       {/* Nawigacja */}
//       <nav className={`navigation ${menuOpen ? 'active' : ''}`}>
//         <ul>
//         <li>
//             <Link to="/about" onClick={() => handleRefresh('/about')}>
//               O mnie
//             </Link>
//           </li>
//           <li>
//             <Link to="/contact" onClick={() => handleRefresh('/contact')}>
//               Kontakt
//             </Link>
//           </li>
//           <li>
//             <Link to="/offer" onClick={() => handleRefresh('/offer')}>
//               Oferta
//             </Link>
//           </li>
//           {/* <li>
//             <Link to="/offer" onClick={() => handleNavigation('process')}>
//               Na czym polega proces?
//             </Link>
//           </li> */}
//           {/* <li>
//             <Link to="/offer" onClick={() => handleNavigation('products')}>
//               Co mogę stworzyć?
//             </Link>
//           </li> */}
//           <li>
//             <Link to="/offer" onClick={() => handleNavigation('custom-orders')}>
//               Zamówienia indywidualne
//             </Link>
//           </li>
//           {/* <li>
//             <Link to="/offer" onClick={() => handleNavigation('how-to-order')}>
//               Jak zamówić?
//             </Link>
//           </li> */}
//           {/* <li>
//             <Link to="/offer" onClick={() => handleNavigation('pricing')}>
//               Cennik
//             </Link>
//           </li> */}
//           {/* <li>
//             <Link to="/offer" onClick={() => handleNavigation('gallery')}>
//               Galeria dzieł
//             </Link>
//           </li> */}
//           {/* <li>
//             <Link to="/ReadyProducts" onClick={() => handleRefresh('/ready-products')}>
//               Gotowe produkty
//             </Link>
//           </li> */}
//         </ul>
//       </nav>
// {/* dupa */}
// {/* haha */}
//       {/* Ikony koszyka i profilu */}
//       {/* <div className="icons">
//         <Link to="/cart" className="cart-icon">
//           <FaShoppingCart />
//           {cartItemCount > 0 && <span className="cart-count">{cartItemCount}</span>}
//         </Link>
//         <div className="user-icon">
//           <FaUser /> */}
//           {/* Menu użytkownika */}
//           {/* <div className="user-menu">
//             {isAuthenticated ? (
//               <>
//                 <Link to="/profile">Profil</Link>
//                 <Link to="/orders">Historia zakupów</Link>
//                 <button onClick={handleLogout}>Wyloguj</button>
//               </>
//             ) : (
//               <>
//               <Link to="/login">Zaloguj</Link>
//               <Link to="/register">Zarejestruj</Link>
//             </>
//             )}
//           </div>
//         </div> */}
//       {/* </div> */}
//     </header>
//   );
// }

// export default Header;
// src/components/Header.js
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import '../styles/Header.scss';
import logo from '../assets/images/logo2.png';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setMenuOpen(false);
    }
  };

  const handleNavigation = (id) => {
    if (location.pathname === '/offer') {
      handleScroll(id);
    } else {
      navigate('/offer');
      setTimeout(() => handleScroll(id), 500);
    }
  };

  const handleRefresh = (path) => {
    if (location.pathname === path) {
      window.location.reload();
    } else {
      navigate(path);
    }
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/" onClick={() => handleRefresh('/')}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      {/* Ikona mobilna */}
      <div className="mobile-menu-icon" onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </div>

      {/* Menu nawigacyjne */}
      <nav className={`navigation ${menuOpen ? 'active' : ''}`}>
        <ul>
          <li>
            <Link to="/about" onClick={() => handleRefresh('/about')}>
              O mnie
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => handleRefresh('/contact')}>
              Kontakt
            </Link>
          </li>
          <li>
            <Link to="/offer" onClick={() => handleRefresh('/offer')}>
              Oferta
            </Link>
          </li>
          <li>
            <Link to="/offer" onClick={() => handleNavigation('custom-orders')}>
              Zamówienia indywidualne
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
