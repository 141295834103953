// import React, { useState } from 'react';
// import '../styles/main.scss';

// function Offer() {
//   const [selectedImage, setSelectedImage] = useState(null);
//   const closeImage = () => setSelectedImage(null);

//   const galleryImages = [
//     'frontend\src\assets\images\logo.png',
//     '/path/to/image2.jpg',
//     '/path/to/image3.jpg'
//   ];

//   return (
//     <div className="offer-container">
//       <h1 id="process">Na czym polega proces?</h1>
//       <div className="offer-section">
//         <p>Proces zalewania kwiatów w żywicy zaczynam od ich odpowiedniego suszenia. W zależności od przeznaczenia, kwiaty suszę w piasku, co pozwala zachować ich naturalny kształt, lub na płasko, aby uzyskać delikatniejszy efekt. Dzięki temu każdy kwiat utrzymuje swoją formę i kolory na długo.</p>
//         <p>Po suszeniu mieszam żywicę epoksydową i wlewam pierwszą warstwę do formy. Następnie ostrożnie układam kwiaty, aby stworzyć idealną kompozycję. Kolejna warstwa żywicy zalewa kwiaty, co pozwala zachować ich naturalny wygląd.</p>
//         <p>W przypadku większych projektów, takich jak bukiety ślubne, proces zalewania odbywa się w kilku etapach, warstwa po warstwie. Dzięki temu każda część bukietu jest idealnie zatopiona w żywicy, a finalny efekt jest trwały i wygląda niezwykle elegancko.</p>
//       </div>
// {/* 
//       <h1 id="products">Co mogę dla Ciebie stworzyć?</h1>
//       <div className="offer-section">
//         <p>Opis produktów, ich wielkości, rodzajów kwiatów. Dodanie grafiki z wymiarami form.</p>
//       </div>

//       <h1 id="custom-orders">Twój bukiet / Zamówienia indywidualne</h1>
//       <div className="offer-section">
//         <p>Opis możliwości stworzenia bukietu z własnymi kwiatami, w tym bukietów ślubnych…</p>
//       </div>

//       <h1 id="how-to-order">Jak zamówić?</h1>
//       <div className="offer-section">
//         <p>Krok po kroku, jak zamówić. Podanie szczegółowego procesu składania zamówienia…</p>
//       </div> */}

//       <h1 id="pricing">Cennik</h1>
//       <div className="offer-section">
//         {/* Możesz tu wstawić grafikę z cennikiem */}
//       </div>

//       {/* <h1 id="gallery">Galeria dzieł</h1>
//       <div className="offer-gallery">
//         {galleryImages.map((image, index) => (
//           <div
//             key={index}
//             className="gallery-thumbnail"
//             onClick={() => setSelectedImage(image)}
//           >
//             <img src={image} alt={`Galeria ${index + 1}`} />
//           </div>
//         ))}
//       </div> */}

//       {selectedImage && (
//         <div className="overlay" onClick={closeImage}>
//           <img src={selectedImage} alt="Powiększony widok" className="expanded-image" />
//         </div>
//       )}
//     </div>
//   );
// }

// export default Offer;

import React, { useState } from 'react';
import '../styles/Offer.scss';

function Offer() {
  const [selectedImage, setSelectedImage] = useState(null);
  const closeImage = () => setSelectedImage(null);

  const galleryImages = [
    '/1.jpg',
    '/2.jpg',
    '/3.jpg'
  ];

  return (
    <div className="offer-container">
      <h1 id="process">Na czym polega proces konserwacji kwiatów?</h1>
      <div className="offer-section">
         <p>Proces zalewania kwiatów w żywicy zaczynam od ich odpowiedniego suszenia. W zależności od przeznaczenia, kwiaty suszę w piasku, co pozwala zachować ich naturalny kształt, lub na płasko, aby uzyskać delikatniejszy efekt. Dzięki temu każdy kwiat utrzymuje swoją formę i kolory na długo.</p>
         <p>Po suszeniu mieszam żywicę epoksydową i wlewam pierwszą warstwę do formy. Następnie ostrożnie układam kwiaty, aby stworzyć idealną kompozycję. Kolejna warstwa żywicy zalewa kwiaty, co pozwala zachować ich naturalny wygląd.</p>
         <p>W przypadku większych projektów, takich jak bukiety ślubne, proces zalewania odbywa się w kilku etapach, warstwa po warstwie. Dzięki temu każda część bukietu jest idealnie zatopiona w żywicy, a finalny efekt jest trwały i wygląda niezwykle elegancko.</p>
       </div>

      <h1 id="preparation">Przygotowanie bukietu do wysyłki</h1>
      <div className="offer-section preparation">
        <p>Przygotuj bukiet do wysyłki, aby dotarł do mnie w najlepszym stanie. Poniżej znajdziesz kroki do właściwego przygotowania i zabezpieczenia:</p>
        <ol>
          <li><strong>Po weselu:</strong> Skróć końcówki roślin o 5 cm, aby lepiej chłonęły wodę. Umieść bukiet w zacienionym, chłodnym miejscu, unikając lodówki.</li>
          <li><strong>Materiały do pakowania:</strong> Potrzebujesz wilgotnej gąbki florystycznej, wysokiego kartonu i wypełniacza (papier, folia bąbelkowa), aby unieruchomić kwiaty.</li>
          <li><strong>Pakowanie:</strong> Namocz gąbkę dzień wcześniej, w dniu wysyłki usuń nadmiar wody. Wbij kwiaty pionowo w gąbkę, zabezpiecz folią i umieść w kartonie, wypełniając wolne przestrzenie.</li>
          <li><strong>Oznaczenie góry kartonu:</strong> Użyj markera lub naklejki, aby wskazać, gdzie jest góra paczki, co ułatwi transport.</li>
          <li><strong>Miejsce oczekiwania:</strong> Przechowuj zapakowany karton w chłodnym, zacienionym miejscu do przyjazdu kuriera.</li>
        </ol>
      </div>

      {/* <h1 id="custom-orders">Twój bukiet / Zamówienia indywidualne</h1>
      <div className="offer-section">
         <p>Opis możliwości stworzenia bukietu z własnymi kwiatami, w tym bukietów ślubnych…</p>
       </div> */}

      <h1 id="how-to-order">Jak zamówić?</h1>
      <div className="offer-section how-to-order">
        <ol className="order-steps">
          <li><strong>Załóż konto i zaloguj się:</strong> Rozpocznij, rejestrując się na stronie i logując do swojego konta.</li>
          <li><strong>Wybierz termin rezerwacji:</strong> W zakładce „Rezerwacja bukietu” wybierz dogodny termin dostarczenia bukietu.</li>
          <li><strong>Opłać zadatek:</strong> Zabezpiecz swój termin, dokonując opłaty.</li>
          <li><strong>Wybierz formę prezentacji bukietu:</strong> Określ, jak chcesz, aby Twój bukiet został utrwalony, i opłać wybraną formę.</li>
          <li><strong>Przygotowanie bukietu do wysyłki:</strong> Zabezpiecz bukiet do transportu, korzystając z instrukcji przygotowania.</li>
          <li><strong>Oczekuj wizualizacji:</strong> Po wysuszeniu bukietu przygotuję dla Ciebie wizualizacje projektu.</li>
          <li><strong>Ciesz się swoim bukietem:</strong> Twój utrwalony bukiet jest gotowy, by cieszyć oczy i serce!</li>
        </ol>
      </div>

      <h1 id="pricing">Cennik</h1>
      <div className="offer-section">
       {/* Możesz tu wstawić grafikę z cennikiem */}
      </div>

      <h1 id="gallery">Galeria dzieł</h1>
      <div className="offer-gallery">
        {galleryImages.map((image, index) => (
          <div
            key={index}
            className="gallery-thumbnail"
            onClick={() => setSelectedImage(image)}
          >
            <img src={image} alt={`Galeria ${index + 1}`} />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="overlay" onClick={closeImage}>
          <img src={selectedImage} alt="Powiększony widok" className="expanded-image" />
        </div>
      )}
    </div>
  );
}

export default Offer;
