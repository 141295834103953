// src/pages/Product.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/ProductPage.scss';

function Product() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [productsList, setProductsList] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Pobieranie bieżącego produktu
    axios.get(`http://localhost:3001/api/product/${slug}`)
      .then(response => {
        setProduct(response.data.product);
        setMainImage(response.data.product.images ? response.data.product.images[0] : response.data.product.image_url);
        setImageLoaded(false);
      })
      .catch(error => {
        console.error('Błąd podczas pobierania produktu:', error);
        setProduct(null);
      });

    // Pobieranie listy wszystkich slugów produktów
    axios.get('http://localhost:3001/api/products')
      .then(response => {
        setProductsList(response.data.products.map(p => p.slug));
      })
      .catch(error => console.error('Błąd podczas pobierania listy produktów:', error));
  }, [slug]);

  useEffect(() => {
    if (mainImage) {
      const img = new Image();
      img.src = mainImage;
      img.onload = () => setImageLoaded(true);
    }
  }, [mainImage]);

  if (!product) {
    return <p>Ładowanie...</p>;
  }

  const navigateToProduct = (direction) => {
    const currentIndex = productsList.indexOf(slug);
    if (currentIndex !== -1 && productsList.length > 0) {
      let newIndex = direction === 'prev' 
        ? (currentIndex - 1 + productsList.length) % productsList.length 
        : (currentIndex + 1) % productsList.length;
      const newSlug = productsList[newIndex];
      navigate(`/produkt/${newSlug}`);
    }
  };

  return (
    <div className="product-page-container">
      <h1 className="product-title">{product.title}</h1>
      <div className="product-page">
        <div className="image-gallery">
          <div className="main-image">
            <img src={mainImage} alt={product.title} className={imageLoaded ? 'loaded' : ''} />
          </div>
          <div className="thumbnail-gallery">
            {product.images && product.images.map((img, idx) => (
              <img 
                key={idx} 
                src={img} 
                alt={`Miniatura ${idx}`} 
                className={`thumbnail ${img === mainImage ? 'active-thumbnail' : ''}`} 
                onClick={() => setMainImage(img)} 
              />
            ))}
          </div>
        </div>
        <div className="product-details">
          <p className="product-description">{product.long_description}</p>
          <p className="product-price">Cena: {product.price} zł</p>
          <button 
            className="add-to-cart-button" 
            onClick={() => alert('Funkcja dodawania do koszyka jeszcze nie jest zaimplementowana.')}
          >
            Do koszyka
          </button>
        </div>
      </div>
      <div className="navigation-buttons">
        <button className="nav-button" onClick={() => navigateToProduct('prev')}>&larr; Poprzedni</button>
        <button className="nav-button" onClick={() => navigateToProduct('next')}>Następny &rarr;</button>
      </div>
    </div>
  );
}

export default Product;
