// src/pages/ReadyProducts.js
import React, { useEffect, useState, useContext } from 'react';
import '../styles/ReadyProducts.scss';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { CartContext } from '../context/CartContext'; // Upewnij się, że ścieżka jest poprawna

function ReadyProducts() {
  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { addToCart, isInCart } = useContext(CartContext);

  const categories = [
    { id: 'zakladki', name: 'Zakładki' },
    { id: 'podkladki', name: 'Podkładki' },
    { id: 'zawieszki', name: 'Zawieszki' },
    { id: 'duze-formy', name: 'Duże formy' },
    { id: 'srednie-formy', name: 'Średnie formy' },
    { id: 'male-formy', name: 'Małe formy' },
  ];

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    axios
      .get('http://localhost:3001/api/products')
      .then((response) => {
        const grouped = response.data.products.reduce((acc, product) => {
          const categoryKey = categories.find(
            (cat) => cat.name.toLowerCase() === product.category?.toLowerCase()
          )?.id;

          if (categoryKey) {
            if (!acc[categoryKey]) acc[categoryKey] = [];
            acc[categoryKey].push(product);
          } else {
            if (!acc['inne']) acc['inne'] = [];
            acc['inne'].push(product);
          }
          return acc;
        }, {});

        setProducts(grouped);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setError(true);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Ładowanie produktów...</p>;
  if (error) return <p>Wystąpił błąd podczas ładowania produktów.</p>;

  return (
    <div className="ready-products-container">
      <aside className="sidebar">
        <ul>
          {categories.map((category) => (
            <li key={category.id} onClick={() => handleScroll(category.id)}>
              {category.name}
            </li>
          ))}
        </ul>
      </aside>

      <main className="products-main">
        {categories.map(({ id, name }) => (
          <section key={id} id={id}>
            <h2>{name}</h2>
            <div className="products-grid">
              {products[id] && products[id].length > 0 ? (
                products[id].map((product) => (
                  <div key={product.id} className="product">
                    <Link to={`/produkt/${product.slug}`}>
                      <img src={product.image_url} alt={product.title} />
                    </Link>
                    <div className="product-info">
                      <div className="price">{product.price} PLN</div>
                      <div className="description">{product.short_description}</div>
                    </div>
                    <button
                      className={`add-to-cart-button ${isInCart(product.id) ? 'in-cart' : ''}`}
                      onClick={() => addToCart(product)}
                      disabled={isInCart(product.id)}
                    >
                      {isInCart(product.id) ? 'W koszyku' : 'Dodaj do koszyka'}
                    </button>
                  </div>
                ))
              ) : (
                <p>Brak produktów w tej kategorii</p>
              )}
            </div>
          </section>
        ))}
      </main>
    </div>
  );
}

export default ReadyProducts;
