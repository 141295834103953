import React, { useState } from 'react';
import axios from 'axios';
import '../styles/AddProduct.scss';
// src/pages/AddProduct.js
// import { CartContext } from '../context/CartContext';


function AddProduct() {
  const [formData, setFormData] = useState({
    tytul: '',
    krotkiOpis: '',
    dlugiOpis: '',
    cena: '',
    kategoria: 'Zakładki',
    zdjecia: []
  });
  const [wiadomosc, setWiadomosc] = useState('');
  const [previewImages, setPreviewImages] = useState([]);

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, zdjecia: files });

    // Generowanie podglądów dla każdego zdjęcia
    const previews = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
      });
    });

    Promise.all(previews).then((images) => setPreviewImages(images));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('tytul', formData.tytul);
    data.append('krotkiOpis', formData.krotkiOpis);
    data.append('dlugiOpis', formData.dlugiOpis);
    data.append('cena', formData.cena);
    data.append('kategoria', formData.kategoria);
    formData.zdjecia.forEach((file) => data.append('zdjecia', file));

    try {
      const response = await axios.post('http://localhost:3001/api/dodajprodukt', data);
      setWiadomosc('Dodano produkt!');
      setTimeout(() => {
        setWiadomosc('');
        setFormData({
          tytul: '',
          krotkiOpis: '',
          dlugiOpis: '',
          cena: '',
          kategoria: 'Zakładki',
          zdjecia: []
        });
        setPreviewImages([]);
        window.open(`http://localhost:3000/produkt/${response.data.product.slug}`, '_blank');
      }, 3000);
    } catch (error) {
      setWiadomosc('Wystąpił błąd podczas dodawania produktu.');
    }
  };

  return (
    <div className="add-product">
      <h1>Dodaj produkt</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="add-product-form">
        <label>
          Tytuł:
          <input type="text" name="tytul" value={formData.tytul} onChange={handleChange} required />
        </label>
        <label>
          Krótki opis:
          <input type="text" name="krotkiOpis" value={formData.krotkiOpis} onChange={handleChange} required />
        </label>
        <label>
          Długi opis:
          <textarea name="dlugiOpis" value={formData.dlugiOpis} onChange={handleChange} required />
        </label>
        <label>
          Cena:
          <input type="number" name="cena" value={formData.cena} onChange={handleChange} required />
        </label>
        <label>
          Kategoria:
          <select name="kategoria" value={formData.kategoria} onChange={handleChange}>
            <option value="Zakładki">Zakładki</option>
            <option value="Podkładki">Podkładki</option>
            <option value="Zawieszki">Zawieszki</option>
            <option value="Duże formy">Duże formy</option>
            <option value="Średnie formy">Średnie formy</option>
            <option value="Małe formy">Małe formy</option>
          </select>
        </label>
        <label>
          Zdjęcia:
          <input type="file" name="zdjecia" multiple onChange={handleFileChange} />
        </label>

        <div className="image-previews">
          {previewImages.map((src, index) => (
            <img key={index} src={src} alt={`Podgląd ${index}`} className="preview-image" />
          ))}
        </div>

        <button type="submit" className="submit-button">Dodaj produkt</button>
      </form>
      {wiadomosc && <p className="message">{wiadomosc}</p>}
    </div>
  );
}

export default AddProduct;
