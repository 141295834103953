import React from 'react';
import '../styles/About.scss'; // Import nowego pliku stylów
import photo from '../assets/images/ania_w_masce.jpg';

function About() {
  const goToOffer = () => {
    window.location.href = '/offer';
  };

  return (
    <div className="about-container">
      <h1>Poznaj moją historię</h1>
      <div className="about-content">
        <img src={photo} alt="Twoje zdjęcie" className="about-image" />
        <div className="about-text">
          <p>Cześć! Jestem Ania.</p> 
          <p>Witam w mojej pracowni, w której tworzę ozdoby z żywicy epoksydowej, które łączą w sobie piękno natury i wyjątkowy design. Specjalizuję się w konserwacji kwiatów, zwłaszcza bukietów ślubnych, które zamieniam w trwałe pamiątki.
              Każdy dzień to dla mnie okazja do nauki i rozwijania nowych pomysłów. Tworzę rzeczy unikalne, które cieszą oko i mają dla Ciebie wyjątkowe znaczenie. Zapraszam do mojego świata żywicy i kwiatów!</p>
          <button className="offer-button" onClick={goToOffer}>
            Zobacz ofertę!
          </button>
        </div>
      </div>
    </div>
  );
}

export default About;
