import React, { useEffect, useState } from 'react';
import api from '../services/api';  // Importuj plik api.js, jeśli go używasz
import '../styles/main.scss';  // Zakładam, że wszystkie style są w pliku main.scss

function Home() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    api.get('/api/message')  // Wywołanie API, aby pobrać wiadomość
      .then((response) => setMessage(response.data.message))
      .catch((error) => console.error('Error:', error));
  }, []);

  return (
    <div className="home-container">
      

      <section className="about-section">
        <h1>Poznaj moją historię</h1>
        <p>{message}</p>  {/* Wiadomość z API */}
        <img src="/path-to-your-image.png" alt="Ania" className="about-image" />
      </section>

    
    </div>
  );
}

export default Home;
